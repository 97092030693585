




























































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import TableComponent from '@common-src/mixins/table-component';
import { MaintenanceEntityModel, MaintenanceQueryModel } from '@common-src/entity-model/maintenance-entity';
import MaintenanceService from '@common-src/service/maintenance';
import MaintenanceDetailComponent from '@/pages/dashboard/maintenance/maintenance-detail.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'maintenance-detail-component': MaintenanceDetailComponent
    }
})
export default class MaintenanceListComponent extends Mixins(TableComponent, DrawerComponent) {
    MaintenanceEntityModel = MaintenanceEntityModel;

    ViewModeType = ViewModeType;

    selectedRecord = '';

    viewType = ViewModeType.VIEW;

    created() {
        this.startFullScreenLoading();

        this.initTable({
            service: MaintenanceService,
            queryModel: new MaintenanceQueryModel(),
            tableColumns: MaintenanceEntityModel.getTableColumns()
        });

        this.getList();

        this.width = '50%';

        this.stopFullScreenLoading();
    }

    editClick(record, viewType) {
        this.selectedRecord = record;

        this.viewType = viewType;

        this.drawerOpen(record);
    }

    showDrawer() {
        this.getList();

        this.drawerVisible = false;
    }

    syncClick() {
        return MaintenanceService.sync().then(() => {
            this.showMessageSuccess('同步成功');
        });
    }
}
